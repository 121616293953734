import { useEffect } from "react";

const MIAWChat = ({ fields }) => {
  const siteUrl = fields.siteUrl.value;

  const initEmbeddedMessaging = () => {
    try {
      window.embeddedservice_bootstrap.settings.language =
        fields.language.value; // For example, enter 'en' or 'en-US'

      window.embeddedservice_bootstrap.init(
        fields.orgId.value,
        fields.embeddedServiceAPIName.value,
        siteUrl,
        {
          scrt2URL: fields.scrtUrl.value,
        },
      );
    } catch (err) {
      console.error("Error loading Embedded Messaging: ", err);
    }
  };

  useEffect(() => {
    if (!window.embeddedservice_bootstrap) {
      // Meta Tag for referrer
      const metaTag = document.createElement("meta");
      metaTag.name = "referrer";
      metaTag.content = "origin";
      document.head.appendChild(metaTag);

      // JS for onload
      const s = document.createElement("script");
      s.setAttribute("src", `${siteUrl}/assets/js/bootstrap.min.js`);
      s.onload = function onloadOverride() {
        initEmbeddedMessaging();
      };
      document.body.appendChild(s);
    }
  }, []);

  return null;
};

export default MIAWChat;
